<template>
  <div class="stand-up">
    <div class="dashboard-summary">
      <StandUpSummary :from="filterValue" :count="standUpCount" />
    </div>
    <div class="dashboard-content py-4">
      <div class="stand-up__actions d-flex justify-content-end">
        <div class="m-width-300 mr-2">
          <SearchBox
            bg-color="#D6DDE4"
            :rounded="5"
            placeholder="Search tasks, blockers and stand up..."
          />
        </div>
        <FilterDropdown variant="faint-blue" :filters="filters" @filtered="setFilter" />
        <b-button variant="link" class="ml-2 btn btn-tertiary text-decoration-none px-3" @click="$bvModal.show('add_stand_up')">
          Add Stand Up
        </b-button>
      </div>
      <div class="stand-up__body mt-3 pb-4">
        <b-table striped hover responsive :fields="fields" :items="items">
          <template #cell(action)="data">
            <OptionsDropdown
              :toggle-class="['text-decoration-none']"
              :options-data="optionsData"
              :options-id="data.item.date"
            />
          </template>
        </b-table>
        <div
          class="m-pagination overflow-auto d-flex p-4 justify-cotnent-between align-items-center"
        >
          <span class="flex-grow-1">
            Showing page {{ currentPage }} of {{ totalRows }}
          </span>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            align="right"
            pills
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <AddStandUp />
  </div>
</template>
<script>
import StandUpSummary from "../../components/stand-up/StandUpSummary.vue";
import SearchBox from "../../components/SearchBox.vue";
import FilterDropdown from "../../components/FilterDropdown.vue";
import OptionsDropdown from "@/components/OptionsDropdown.vue";
import AddStandUp from "../../components/stand-up/AddStandUp.vue";
export default {
  name: "StandUp",
  components: {
    StandUpSummary,
    SearchBox,
    FilterDropdown,
    OptionsDropdown,
    AddStandUp,
  },
  data() {
    return {
      standUpCount: 23,
      filterValue: "weekly",
      perPage: 3,
      currentPage: 1,
      optionsData: [
        {
          name: "Edit",
          value: "edit",
          class: ["text-tertiary"],
        },
        {
          name: "View Details",
          value: "view",
          class: [""],
        },
        {
          name: "Remove",
          value: "remove",
          class: ["text-secondary"],
        },
      ],
      filters: [
        {
          name: "Today",
          value: "today",
        },
        {
          name: "Weekly",
          value: "weekly",
        },
        {
          name: "Monthly",
          value: "monthly",
        },
      ],
      fields: [
        {
          key: "date",
          sortable: true,
        },
        {
          key: "current_task",
        },
        {
          key: "previous_task",
        },
        {
          key: "blocker",
        },
        {
          key: "action",
        },
      ],
      items: [
        {
          date: "2022-02-26",
          current_task: "I'll be working on the time machine",
          previous_task: "Created a flying horse cart",
          blocker: "UI designs is currently blocking me",
        },
        {
          date: "2022-02-26",
          current_task: "I'll be working on the time machine",
          previous_task: "Created a flying horse cart",
          blocker: "UI designs is currently blocking me",
        },
        {
          date: "2022-02-26",
          current_task: "I'll be working on the time machine",
          previous_task: "Created a flying horse cart",
          blocker: "UI designs is currently blocking me",
        },
      ],
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    setFilter(value) {
      console.log(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.stand-up {
  &__body {
    background-color: $white;
    border-radius: $size-10;
  }
}
</style>
