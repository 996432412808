<template>
  <primary-modal
    ref="addStandUp"
    modal-id="add_stand_up"
    :title="this.isLoading ? 'Please wait' : 'Add Stand Up'"
  > 
    <div class="add-stand-up p-3"> 
      <p>Every weekday let your manager know  about what you’re working on , your work pllan and any blockers you have. Remember to be on time.</p>
      <b-form>
        <div class="mb-3">
          <p class="add-stand-up__current-day m-0">{{currentDay()}}</p>
          <span class="add-stand-up__current-date mt-2">{{currentDate()}}</span>
        </div>
        <b-form-group
            class="mb-4"
            label="What did you work on yesterday"
            label-for="workedOn"
        >
          <b-form-textarea
            id="textarea"
            v-model="text"
            placeholder="Type Here..."
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
            class="mb-4"  
            label="What will you be working on today" 
            label-for="workingOn"
            description="Share a 1-2  sentence summary of your days task or past ticket URL"
        >
          <b-form-textarea
            id="textarea"
            v-model="text"
            placeholder="Type Here..."
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
            class="mb-4"
            label="What issue are bloacking you(Optional)"
            label-for="blockers"
        >
          <b-form-textarea
            id="textarea"
            v-model="text"
            placeholder="Type Here..."
          ></b-form-textarea>
        </b-form-group>       
        <b-button block type="submit" variant="primary" class="mt-5">
          {{ isLoading ? "" : "Submit" }}
          <RingLoader v-if="isLoading" />
        </b-button>
      </b-form>
    </div>
  </primary-modal>
</template>
<script>
import PrimaryModal from "@/components/PrimaryModal.vue";
import RingLoader from "@/components/loader/RingLoader";
import moment from 'moment';
export default {
  name: "AddStandUp",
  components: {
    PrimaryModal, 
    RingLoader,
  },
  
  methods: {
    currentDate(date) {
      return moment(date).format("MMMM M")
    },
    currentDay(date) {
      return moment(date).format("dddd")
    }
  },
    
};
</script>

<style lang="scss" scoped>
.add-stand-up {
  p {
      color: $grey-tint-2;
      font-size: 0.75rem;
  }
  &__current-date{
    color: #2E2D32;
    font-size: 18px
  }
}
</style>