<template>
  <b-dropdown
    :variant="variant"
    class="options-dropdown"
    :toggle-class="computedToggleClass"
    :no-caret="noCaret"
  >
    <template #button-content>
      <slot>
        <EllipsisIcon />
      </slot>
    </template>
    <b-dropdown-item
      v-for="(option, i) in optionsData"
      :key="i"
      :class="option.class"
      @click="setOption"
    >
      {{ option.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
import EllipsisIcon from "@/components/svg/EllipsisIcon.vue";
export default {
  name: "OptionsDropdown",
  components: {
    EllipsisIcon,
  },
  props: {
    variant: {
      type: String,
      default: "link",
    },
    toggleClass: {
      type: Array,
      default: () => [],
    },
    noCaret: {
      type: Boolean,
      default: true,
    },
    noPadding: {
      type: Boolean,
      default: true,
    },
    optionsData: {
      type: Array,
      default: () => [],
    },
    optionsId: {
      type: String,
      default: "",
    },
  },
  computed: {
    computedToggleClass() {
      return [...this.toggleClass, { "options-dropdown__no-padding": this.noPadding }];
    },
  },
  methods: {
    setOption() {
      console.log("setOption");
    },
  },
};
</script>
